.dropdownTrigger {
  position: relative;
}

.openBtn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);

  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.dropdownOpen {
  & input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-radius: 6px;
    }
  }
}

.optionsList {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Two columns of equal width */
  gap: 10px;  /* Space between columns and rows */
  margin: 0;
  padding: 10px;  /* Padding around the grid */
  width: 100%;
  max-height: 194px;
  overflow-y: auto;
  border: 1px solid var(--colorGrey100);
  background-color: var(--colorWhite);
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  P {
    font-weight: var(--fontWeightBold);
  }
}

.option {
  position: relative;
  padding: 4px 12px 4px 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    cursor: pointer;
    background-color: var(--colorGrey100);
  }
}

.optionIcon svg {
  height: 20px;
  width: 20px;
}

.checkIcon {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0, -25%);
  stroke: var(--colorGrey500);
}

.chipsContainer {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 80px;
}

.noIconChip {
  .chipContent {
    padding-left: 12px;
  }
}

.chip {
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: var(--colorGrey100);
  border-radius: 16px;

  font-size: 14px;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
}

.chipIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;

  user-select: none;
  border-radius: 50%;
  border: 1px solid var(--colorGrey500);
  background-color: var(--colorWhite);

  svg {
    width: 26px;
    height: 26px;
  }
}

.chipContent {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
}

.closeBtn {
  border: none;
  margin: 0;
  padding: 0;

  display: inline-flex;
}

.closeIcon {
  margin-right: 6px;
  padding: 2px;

  border-radius: 9999px;
  background-color: var(--colorWhite);

  height: 14px;
  width: 14px;
  stroke-width: 0.5;

  transition: transform 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
