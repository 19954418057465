@import '../../styles/customMediaQueries.css';

.root {
}

.label {
  @media (--viewportMedium) {
    padding-bottom: 6px;
  }
}

.desc {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--colorGrey300);
  margin-top: 0;
  margin-bottom: 13px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}

.input {
  display: flex;
  align-items: center;

  & > select {
    flex: 1 1 200px;
    box-shadow: none;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover,
    &:focus {
      border-right: 0;
    }
  }

  & > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.verifyBtn {
  min-height: 36px;
  padding: 0 12px;
  margin-left: 6px;
  width: auto;

  @media (--viewportMedium) {
    min-height: 48px;
    padding: 8px 24px;
  }
}

.status {
  margin-top: 8px;
  text-align: center;
  color: var(--colorGrey500);
}

.statusFailed {
  color: var(--colorFail);
}

.statusProcessing {
  color: var(--colorAttention);
}

.statusVerified {
  color: var(--colorSuccess);
}
