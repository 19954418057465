.password {
  composes: marketplaceModalPasswordMargins from global;
}

.togglePasswordButton {
  width: 40px;
  border: none;
  cursor: pointer;
  padding: 0 8px;
}

.suffixContainer {
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;  /* Initially hidden */
  transform: translateY(10px);
  pointer-events: none;
  margin: auto;
}

.suffixVisible {
  opacity: 1;
  transform: translateY(0); /* Move to final position when visible */
  pointer-events: auto;
}
