.carouselContainer {
  position: relative;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.carouselWrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carouselItem {
  flex: 0 0 100%;
}

.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.prevIcon {
  scale: -1;
  stroke: var(--spxGreyLight);
}

.nextIcon {
  stroke: var(--spxGreyLight);
}

.prevButton,
.nextButton {
  border: none;
  padding: 8px;
  cursor: pointer;
  pointer-events: all;
  z-index: 1;
}

.prevIcon:hover,
.nextIcon:hover {
  stroke: var(--colorGrey300);
}

.dotsContainer {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--colorGrey200);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.activeDot {
  background-color: var(--spxGrey);
}
