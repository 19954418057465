.accordion {
  display: flex;
  flex-direction: column;
  border: var(--borderInputField);

  border-radius: var(--borderRadiusMedium);
  background-color: var(--colorWhite);
}

.accordionTrigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  transition: background-color 0.6s ease;
  padding: 12px;
}

.accordionIcon {
  margin: 0 0 0 12px;
}

/* Style the accordion content panel. Note: hidden by default */
.accordionContent {
  overflow: auto;
  transition: max-height 0.6s ease;
}

.accordionChildren {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
}
