@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.videoWrapper {
  position: relative;
  width: 100%;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: var(--marketplaceColor);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;
  z-index: 1000;
  border-top-right-radius: 6px;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--colorWhite);
    stroke: var(--colorWhite);
  }
}

.videos {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.invalidUrl {
  display: flex;
  flex-direction: column;
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.inputError {
  border-color: var(--colorFail);

  &:hover {
    border-color: var(--colorFail);
  }
}
