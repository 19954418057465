.root {
}

.inputError {
  border: var(--borderErrorField);
}

.togglePasswordButton {
  width: 40px;
  border: none;
  cursor: pointer;
  padding: 0 8px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.passwordWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 1px solid #d8dce6;
  border-radius: 8px;
  input {
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.charCount {
  text-align: right;
  color: var(--colorGrey400);
  font-size: 12px;
  padding: 8px 0 0 0;
  margin: 0;
}

.charCountLimit {
  color: var(--colorFail);
}
