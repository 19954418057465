.price {
  border-bottom: var(--borderInputField);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);

  &:hover {
    cursor: not-allowed;
  }
}

.label {
  font-size: 13px;
  font-weight: var(--fontWeightSemiBold);
}
