.editor {
  composes: marketplaceInputStyles from global;

  max-height: 800px;
  overflow-y: auto;

  &:not(.readOnly) {
    padding-left: 60px;
  }

  & :global(.image-tool .cdx-button) {
    white-space: break-spaces;
  }

  & :global(.image-tool__caption) {
    display: none;
  }
}

.readOnly {
  & :global(.codex-editor__redactor) {
    padding-bottom: 0 !important;
  }
}

.error {
  border: var(--borderErrorField);
}
