@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  border: var(--borderInputField);
  border-radius: 8px;
  box-shadow: var(--boxShadowListingCard);

  min-width: 200px;
  position: relative;

  /* Remove link's hover effect */
  &:hover,
  &:focus,
  &:focus-within {
    text-decoration: none;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      & .wishlist {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.carouselContainer {
  position: relative;
}

.price {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: var(--colorLightTransparent);
  padding: 8px;
  border-radius: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  padding-top: 8px;
}

.tagline {
  composes: textSmall from global;
  color: var(--colorGrey500);

  font-size: 12px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 48px;

  @media (--viewportSmall) {
    color: var(--colorGrey500);
    font-size: 12px;
  }
}

.info {
  padding: 8px 16px;
}

.authorAndCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.avatar {
  min-width: 24px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  position: relative;

  width: 60%;

  & > svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    fill: var(--spxGreen);
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.category {
  font-size: 13px;
  line-height: 14px;
  text-align: right;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.wishlist {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
  transition: opacity 0.2s;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -8px;
  margin-top: 8px;

  @media (hover: hover) and (pointer: fine) {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
}

.wishlistCta {
  width: 100%;
  padding: 12px 16px;
  min-height: unset;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
  border-radius: inherit;

  &:hover {
    background-color: var(--marketplaceColor);
  }

  & > svg {
    stroke: var(--colorWhite);
  }
}

.inWishlist {
  &:hover {
    background-color: var(--colorFail);
  }
}

.heartIcon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.wishlistSpinner {
  height: 16px;
  width: 16px;
  stroke: var(--colorWhite);
  stroke-width: 4px;
}
