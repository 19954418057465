.root {
  cursor: grab;
  transition: transform 0.2s ease;
}

.dragging {
  opacity: 0.5;
  transform: scale(1.05);
  z-index: 1000;
  position: relative;
}
